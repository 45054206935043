import { reactive } from "vue"
import http from "@/utils/http"

/**
 * Primitive frontend store in memory.
 * @type {{
 * user: {Object},
 * settings: {Object},
 * lastRequest: {null|Number},
 * version: {string|null},
 * auditorField: {Array},
 * auditorFieldFetch: {Function}
 * }
 */
export const store = reactive({
  /**
   * User object, or false if not logged in.
   * @type {{
   *             username: String,
   *             email: String,
   *             firstname: String,
   *             lastname: String,
   *             signature: String,
   *             email: String,
   *             roles: Array
   *          }|false}
   */
  user: false,
  /**
   * User settings.
   * @type {Object}
   */
  settings: {},
  /**
   * Timestamp of the last request. Used to log out after a certain time.
   * @type {null|Number}
   */
  lastRequest: null,
  /**
   * Version of the application.
   * @type {string|null}
   */
  version: "-",
  /**
   * List of auditors for the auditor column.
   * @type {Array}
   */
  auditorField: [],
  /**
   * Fetches the user object from the server.
   * @return {Promise<void>}
   */
  auditorFieldFetch: async () => {
    http.get("/users/auditor-field").then((response) => {
      store.auditorField = response.data
    })
  },
})

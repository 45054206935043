<template>
  <template v-if="!loggedIn">
    <LoginView />
  </template>
  <template v-else>
    <NavbarComponent />
    <router-view :key="$route.path" />
  </template>
  <ToastComponent />
</template>
<script>
import LoginView from "@/views/LoginView.vue"
import NavbarComponent from "@/components/NavbarComponent.vue"
import ToastComponent from "@/components/ToastComponent.vue"
import { store } from "@/services/store.service"
import { ui } from "@/services/ui.service"

export default {
  name: "app",
  components: { ToastComponent, NavbarComponent, LoginView },
  created() {
    // some initialization stuff
    ui.checkForPublicHolidays()
    store.auditorFieldFetch()

    // Set the title of the page
    if (["local", "develop"].includes(process.env.VUE_APP_ENV)) {
      document.title = `[${process.env.VUE_APP_ENV}] GIMBEL Workforce`
    }
  },
  computed: {
    loggedIn() {
      return store.user ?? false
    },
  },
}
</script>

<style lang="scss">
@import "./assets/styles";
</style>

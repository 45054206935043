<template>
  <ul class="eggfield">
    <li
      v-for="n in 50"
      :key="n"
    >
      <img
        :src="getRandomEgg()"
        class="position-relative me-1"
        height="40"
        width="40"
        style="top: -4px"
        alt="Easter Egg"
      />
    </li>
  </ul>
</template>

<script setup>
const getRandomEgg = () => {
  const baseName = "easter-egg-"
  const numberOfEggs = 14 // Update with your actual number of SVGs
  const weights = Array.from({ length: numberOfEggs }, (_, i) =>
    Math.max((numberOfEggs - i) * (numberOfEggs - i), 1),
  )
  const sumOfWeights = weights.reduce((acc, val) => acc + val, 0)
  let randomIndex = Math.floor(Math.random() * sumOfWeights)

  for (let i = 0; i < weights.length; i++) {
    if (randomIndex < weights[i]) {
      // Dynamically construct the path and require the asset
      // eslint-disable-next-line security/detect-non-literal-require
      return require(`@/assets/easter/${baseName}(${i + 1}).svg`)
    }
    randomIndex -= weights[i]
  }

  return require(`@/assets/easter/${baseName}1.svg`) // Fallback
}
</script>

<style lang="scss">
$globe-width: 14px;
$globe-height: 28px;
$globe-spacing: 80px;
$globe-spread: 6px;
$light-off-opacity: 0.3;

.eggfield {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  top: 40px;
  opacity: 0.7;

  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: $globe-spacing/2;
    display: inline-block;
  }
}
</style>
